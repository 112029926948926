import React, { useContext } from 'react'
import { Button } from '@ralston-instruments/app.fieldlab.components.web/button'
import PropTypes from 'prop-types'
import * as riPropTypes from '@/util/riPropTypes'
import { PanelBodyContext } from '@/components/organisms/Panel'
import createClassName from '@/util/createClassName'

const FormActions = ({
  text,
  componentState = 'primary',
  cancelText = 'Cancel',
  cancelAction,
  saveText = 'Save',
  saveAction,
  saveDisabled,
  saveActionComponentState = 'primary',
  cancelRef,
  saveRef,
}) => {
  const { panelType } = useContext(PanelBodyContext)

  return (
    <div
      className={createClassName([
        'flex-shrink-0 px-4 py-5 sm:px-6 flex gap-4 items-center justify-end',
        panelType === 'full' && 'border-t border-gray-200',
        componentState === 'error' && 'bg-red-50 text-red-700',
      ])}
    >
      {text && <div className="flex-1">{text}</div>}
      <div className="flex gap-2 justify-end items-center">
        {cancelAction && (
          <Button
            onClick={cancelAction}
            componentState="default"
            text={cancelText}
            ref={cancelRef}
          />
        )}
        {saveAction &&
          (saveAction === 'submit' ? (
            <Button
              type="submit"
              componentState={saveActionComponentState}
              text={saveText}
              disabled={saveDisabled}
              ref={saveRef}
            />
          ) : (
            <Button
              onClick={saveAction}
              componentState={saveActionComponentState}
              text={saveText}
              disabled={saveDisabled}
              ref={saveRef}
            />
          ))}
      </div>
    </div>
  )
}

FormActions.propTypes = {
  text: PropTypes.node,
  componentState: riPropTypes.componentState,
  cancelText: PropTypes.node,
  cancelAction: PropTypes.func,
  saveText: PropTypes.node,
  saveAction: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: riPropTypes.size,
  saveDisabled: PropTypes.bool,
}

export default FormActions
